@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "vcr";
  src: url("../public/VCR.ttf");
}
@font-face {
  font-family: "gothic";
  src: url("../public/Gothic.ttf");
}
.darkModeBtn {
  @apply bg-[#262626] text-white rounded-md px-3 h-[35px] tab:h-[42px] border-[1px] border-[#A4A4A4] gap-2 hover:bg-opacity-80 
  text-[12px]
  tab:text-[17px];
}
.betBtn {
  @apply bg-[#262626] text-white rounded-md w-[80px] h-[42px] border-[1px] border-[#A4A4A4] gap-2 hover:bg-opacity-80 text-[17px] font-thin flex justify-center items-center;
}

.maxWithdrawalBtn {
  @apply text-black border-[2px] py-2 bg-white border-black hover:bg-gray-300
  rounded-md text-xl  px-4  flex justify-center items-center;
}

.rouletteBet {
  @apply bg-gray-500 text-white rounded-md w-[60px] h-[42px] border-[1px] border-[#A4A4A4] gap-2 hover:bg-opacity-80 text-[15px] font-thin flex justify-center items-center;
}

.headsTailsActiveBtn {
  @apply bg-[#262626] text-white rounded-md  h-[50px] border-[1px] border-[#A4A4A4] gap-2  text-[25px] font-thin w-[50%] flex justify-center items-center;
}

.headsTailsNonActiveBtn {
  @apply opacity-50 hover:opacity-90 bg-[#262626] text-white rounded-md  h-[50px] border-[1px] border-[#A4A4A4] gap-2 hover:bg-opacity-80 text-[25px] font-thin w-[50%] flex justify-center items-center;
}

.bankModalContainer {
  @apply bg-[#262626] text-white rounded-3xl px-3 h-[420px] w-[90%] max-w-2xl border-[1px] border-[#A4A4A4] text-[17px];
}
.walletHeaderBtn {
  @apply bg-[#262626] text-white rounded-md px-3 tab:px-0 tab:w-[140px] h-[35px] tab:h-[42px] border-[1px] border-[#A4A4A4] gap-2 hover:bg-opacity-80 font-thin flex justify-center items-center
  text-[12px]
  tab:text-[17px];
}
.modalMenuBtn {
  @apply text-[30px] text-black bg-white hover:bg-gray-300 border-[1px] rounded-xl w-[210px] gap-2 border-black flex justify-center items-center;
}
.lightModeBtn {
  @apply bg-[#fff] rounded-md gap-2 px-3 h-[42px] border-[1px] border-black text-black text-[17px];
}
.bankModalX {
  @apply w-[38px] h-[36px] rounded-full border-[1px] border-white text-[25px] absolute right-4 top-4 flex justify-center items-center hover:border-black hover:bg-white hover:text-black;
}

.bankModalBack {
  @apply text-[25px] absolute right-6 top-4 flex justify-center items-center  hover:text-gray-300;
}
.walletBtn {
  @apply bg-[#AC162D] hover:bg-[#9C1227] rounded-md h-[48px] border-[1px] border-black text-[23px] text-white px-6;
}
.confirmDepositBtn {
  @apply bg-[#AC162D] hover:bg-[#9C1227] rounded-md h-[48px] border-[1px] border-black text-[23px] text-white flex justify-center items-center w-[70%];
}

.withdrawProcessing {
  @apply bg-[#9C1227] rounded-md h-[48px] border-[1px] border-black text-[23px] text-white flex justify-center cursor-not-allowed items-center w-[70%];
}
.flipBtn {
  @apply bg-[#AC162D] hover:bg-[#9C1227] rounded-md h-[48px] border-[1px] border-black text-[23px] text-white w-[250px] flex justify-center items-center;
}

.flipBtnNonActive {
  @apply bg-[#9C1227] cursor-not-allowed bg-opacity-70 rounded-md h-[48px] border-[1px] border-black text-[23px] text-white w-[250px] flex justify-center items-center;
}

.green {
  @apply h-[50px] w-[50px] border-[0.5px] border-white rounded-full bg-green-600;
}
.red {
  @apply h-[50px] w-[50px] border-[0.5px] border-white rounded-full bg-red-600;
}
.black {
  @apply h-[50px] w-[50px] border-[0.5px] border-white rounded-full bg-black;
}
.underline {
  text-decoration: underline;
}
.live-feed-container {
  position: relative;

  background-repeat: no-repeat;
  background-size: 100% 100%; /* Adjust this as per your image size */
  background-position: center;
  /* Add padding to ensure the content doesn't overlap the image */
  @apply tab:bg-coinFlipFrame tab:p-[70px];
}
/* Fade Black Box */
.fadeBox {
  box-shadow: 0px 0px 70px 70px rgb(0 0 0 / 100%);
}
/* 
.leader::-webkit-scrollbar {
  width: 6px;
}
.leader::-webkit-scrollbar-track {
  background-color: #4b3131;
}
.leader::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
} */
/* Scrollbar modification */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: black;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}
.leaderboard-scroll {
  overflow-y: scroll;
}

.leaderboard-scroll::-webkit-scrollbar {
  width: 6px;
}
.leaderboard-scroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}
.leaderboard-scroll::-webkit-scrollbar-track {
  background-color: #4b3131;
}
.roulette-scroll {
  overflow-y: scroll;
}
.roulette-scroll::-webkit-scrollbar {
  width: 6px;
}
.roulette-scroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}
.roulette-scroll::-webkit-scrollbar-track {
  background-color: #262626;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ========================= 🍞 TOAST STYLING 🍞 ========================= */
.toastBodyError {
  @apply font-gothic text-base  text-red-500;
}

.toastBodyPending {
  @apply font-gothic text-base  text-yellow-600;
}

.toastBodySuccess {
  @apply font-gothic text-base  text-green-600;
}

/* ========================= ROULETTE WHEEL ========================= */
@import url("https://fonts.googleapis.com/css?family=Arvo");
body {
  background: rgb(25, 25, 33);
}
.raffle-roller {
  height: 100px;
  position: relative;
  margin: 60px auto 30px auto;
  width: 900px;
}
.raffle-roller-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
}
.raffle-roller-holder {
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid #3c3759;
}
.raffle-roller-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
}
.raffle-roller-container {
  width: 9999999999999999999px;
  max-width: 999999999999999999px;
  height: 100px;
  background: #191726;
  margin-left: 0;
  transition: all 8s cubic-bezier(0.08, 0.6, 0, 1);
}
.raffle-roller-holder:before {
  content: "";
  position: absolute;
  border: none;
  z-index: 12222225;
  width: 5px;
  height: 100%;
  left: 50%;
  background: #d16266;
}
.item {
  display: inline-block;
  float: left;
  margin: 4px 0px 0.5px 5px;
  width: 85px;
  height: 88px;
  float: left;
  border: 1px solid #70677c;
  background: #14202b;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.class_red_item {
}
img {
  border: 0;
  vertical-align: middle;
}
.winning-item {
  border: 2px solid #66b233;
  position: relative;
  top: -1px;
  border-bottom: 4px solid #66b233;
}
.inventory {
  margin: 0 auto;
  width: 960px;
  max-width: 953px;
  padding: 10px 15px 6px;
  height: auto;
  border: 2px solid #1c3344;
  background: #0e1a23;
}
.inventory > .item {
  float: none;
  cursor: pointer;
  margin: 4px 2px 0.5px 2px;
}
.inventory > .item:hover {
  background-size: 90%;
  background-color: #182a38;
}
.inventory > .item:active {
  height: 83px;
  width: 80px;
  position: relative;
  top: -2px;
  border: 2px solid #356d27;
  border-bottom: 4px solid #356d27;
}
